import { Box } from "@material-ui/core";

import SiteFooter from "@cosy/components/SiteFooter";

export default function SiteLayout({ children, ...props }) {
  return (
    <Box
      display="flex"
      alignItems="stretch"
      justifyContent="stretch"
      minHeight="100vh"
      flexDirection="column"
      {...props}
    >
      {children}
      <Box mt="auto">
        <SiteFooter />
      </Box>
    </Box>
  );
}

export const getLayout = (page) => <SiteLayout>{page}</SiteLayout>;
