import { Box, Button, Typography } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Head from "next/head";
import Image from "next/image";
import { rgba } from "polished";
import { useCallback, useEffect, useState } from "react";

import heroImg from "../public/img/homepage/hero-4096.png";

import InternalLink from "@cosy/components/InternalLink";
import { CustomThemeProvider } from "@cosy/hooks/use_custom_theme";
import useLastVisitedWorkspace from "@cosy/hooks/use_last_visited_workspace";
import SiteLayout from "@cosy/layouts/Site";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      alignItems: "flex-start",
    },
  },
  contentContainer: {
    transition: "all 50ms ease-out",
    padding: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(10),
      width: "48%",
      maxWidth: 800,
      position: "relative",
      top: "5vh",
    },
  },
  imageContainer: {
    transition: "all 50ms ease-in-out",
    width: "110%",
    height: "110%",
    position: "fixed",
    top: "-2.5%",
    left: "-2.5%",
  },
  userSection: {
    position: "absolute",
    top: theme.spacing(12),
    right: theme.spacing(2),
    zIndex: 2,
    [theme.breakpoints.up("md")]: {
      top: theme.spacing(10),
    },
  },
  topBand: {
    backgroundColor: rgba(theme.palette.background.default, 0.5),
    color: theme.palette.text.secondary,
    padding: theme.spacing(2, 4),
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 10),
      textAlign: "left",
    },
  },
  imageTransition: {
    transition: "all 300ms ease-out",
    filter: "saturate(180%)",
    transform: "scale(1.05)",
    opacity: 0.5,
    "&.loaded": {
      filter: "saturate(100%)",
      transform: "scale(1)",
      [theme.breakpoints.up("md")]: {
        "&": {
          opacity: 1,
        },
      },
    },
  },
}));

const metaDescription =
  "Your home screen for work. Instant workplace search and personalized widgets, all connected to the apps you use every day.";

const _HOMEPAGE_THEME = {
  typography: {
    h1: {
      fontSize: "4rem",
      lineHeight: 0.9,
      letterSpacing: "-0.025em",
    },
    body1: {
      fontSize: "1.2rem",
    },
  },
  palette: {
    type: "dark",
    primary: {
      main: "#ff1559",
    },
    secondary: {
      main: "#e3e3e5",
    },
    background: {
      default: "#0d1116",
    },
  },
};

export default function HomePage() {
  return (
    <CustomThemeProvider initialValue={_HOMEPAGE_THEME}>
      <CssBaseline />
      <HomePageContent />
    </CustomThemeProvider>
  );
}

function HomePageContent() {
  const classes = useStyles();

  const [xFromCenter, setXFromCenter] = useState(0);
  const [yFromCenter, setYFromCenter] = useState(0);

  const parallax = useCallback((e) => {
    setXFromCenter(e.view.innerWidth / 2 - e.clientX);
    setYFromCenter(e.view.innerHeight / 2 - e.clientY);
  }, []);

  // We’d redirect if they had an auth token, so this is only to display a Login
  // link if they’ve logged in before.
  const [lastVisitedWorkspace] = useLastVisitedWorkspace();

  useEffect(() => {
    document.addEventListener("mousemove", parallax);
    return () => document.removeEventListener("mousemove", parallax);
  });

  const contentTranslate = useParallaxTransform(
    xFromCenter,
    yFromCenter,
    0.005
  );

  const imageTranslate = useParallaxTransform(xFromCenter, yFromCenter, 0.01);

  return (
    <SiteLayout>
      <HeadTags />

      <div className={classes.container}>
        <div
          className={classes.imageContainer}
          style={{
            transform: imageTranslate,
          }}
        >
          <FadeInImage />
        </div>
        <div
          className={classes.contentContainer}
          style={{
            transform: contentTranslate,
          }}
        >
          <Box mb={5}>
            <Box mb={5}>
              <img
                src="/img/homepage/logo-gray.svg"
                alt="Cosy logo"
                width="100"
                height="26"
              />
            </Box>
            <Typography variant="h1" paragraph>
              Get on the same&nbsp;page
            </Typography>
            <Typography variant="body1" color="secondary" paragraph>
              Cosy’s a home screen for work. Open a new tab and you’re seconds
              away from the files, people and information you need to get your
              job done. Personalized widgets that surface the most important
              information to you, and a fast, powerful company search tool. We
              think you’re going to love it.
            </Typography>
          </Box>
        </div>
      </div>
      {!!lastVisitedWorkspace && (
        <div className={classes.userSection}>
          <InternalLink
            href={`/login`}
            linkComponent={Button}
            linkProps={{
              variant: "outlined",
            }}
          >
            Login
          </InternalLink>
        </div>
      )}
    </SiteLayout>
  );
}

function HeadTags() {
  return (
    <Head>
      <title>Cosy · Company home screen &amp; workplace search</title>
      <link
        href="https://www.googletagmanager.com"
        rel="preconnect"
        crossOrigin
      />
      <link
        href="https://www.google-analytics.com"
        rel="preconnect"
        crossOrigin
      />

      <meta name="description" content={metaDescription} />
      <meta property="og:title" content="Cosy" key="title" />
      <meta
        property="og:description"
        content={metaDescription}
        key="description"
      />
      <meta
        property="og:image"
        content={`${process.env.NEXT_PUBLIC_HOST}/img/favicon/og.png`}
      />
      <meta
        property="og:url"
        content={process.env.NEXT_PUBLIC_HOST}
        key="url"
      />
      <meta property="og:site_name" content="Cosy" />

      <meta name="twitter:site" content="@cosy_app" />
      <meta name="twitter:card" content="summary_large_image" />

      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GA_TRACKING_ID}`}
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${process.env.GA_TRACKING_ID}');
        `,
        }}
      />
    </Head>
  );
}

function useParallaxTransform(xOffset, yOffset, scale) {
  return `translate3d(${xOffset * scale}px,${yOffset * scale}px,0)`;
}

function FadeInImage() {
  const [loaded, setLoaded] = useState(false);
  const classes = useStyles();

  return (
    <div data-testid="fade-in-image">
      <Image
        src={heroImg}
        alt="Cosy user interface"
        layout="fill"
        objectFit="cover"
        quality={100}
        placeholder="blur"
        priority
        className={clsx(classes.imageTransition, { loaded })}
        onLoadingComplete={() => setLoaded(true)}
      />
    </div>
  );
}

HomePage.authRequired = false;
