import { Box, Link, Typography } from "@material-ui/core";

import InternalLink from "@cosy/components/InternalLink";

export default function SiteFooter() {
  return (
    <Box
      p={4}
      display="flex"
      justifyContent="flex-end"
      zIndex="100"
      position="relative"
    >
      <Typography variant="body1">
        <InternalLink href="/privacy-policy" linkProps={{ color: "inherit" }}>
          Privacy Policy
        </InternalLink>{" "}
        ·{" "}
        <Link href="https://twitter.com/cosy_app" color="inherit">
          @cosy_app
        </Link>
      </Typography>
    </Box>
  );
}
